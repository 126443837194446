import React from "react";
import { graphql } from "gatsby";
import { SliceZone } from "@prismicio/react";
import { Container } from "react-bootstrap";
import Seo from "../components/meta/Seo";
import Layout from "../components/common/Layout";

import components from "../components/slices";

const SoftwarePage = ({ data }) => {
  if (!data) return null;

  const { page } = data;
  // console.log(page);

  return (
    <>
      <Seo page={page} />
      <Layout>
        <div
          className="container-fluid text-center py-5"
          style={{ backgroundColor: "#F5F5F5" }}
        >
          <h2>{page.data.title.text}</h2>
        </div>
        <Container>
          <div className="row">
            <SliceZone components={components} slices={page.data.body} />
          </div>
        </Container>
      </Layout>
    </>
  );
};

export const query = graphql`
  query softwareQuery($lang: String, $id: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicSoftwarePage(lang: { eq: $lang }, id: { eq: $id }) {
      alternate_languages {
        uid
        type
        lang
      }
      lang
      type
      url
      data {
        meta_title
        meta_description
        meta_image {
          alt
          dimensions {
            height
            width
          }
          url
        }
        body {
          ... on PrismicSoftwarePageDataBodyContactForOffer {
            id
            slice_type
            slice_label
            primary {
              text {
                richText
              }
            }
          }
          ... on PrismicSoftwarePageDataBodyPdfViewer {
            id
            slice_label
            slice_type
            primary {
              pdf {
                url
              }
            }
          }
          ... on PrismicSoftwarePageDataBodyImageWithText {
            id
            primary {
              content {
                richText
              }
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              title1 {
                text
              }
            }
            slice_label
            slice_type
          }

          ... on PrismicSoftwarePageDataBodyHeading {
            id
            slice_type
            slice_label
            primary {
              heading {
                text
              }
            }
          }
          ... on PrismicSoftwarePageDataBodyImageWithTextVertical {
            id
            slice_type
            slice_label
            primary {
              content {
                richText
              }
              heading {
                text
              }
            }
            items {
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          ... on PrismicSoftwarePageDataBodyRichText {
            id
            slice_type
            slice_label
            primary {
              text {
                richText
              }
            }
          }
          ... on PrismicSoftwarePageDataBodyTabsWithText {
            id
            slice_type
            slice_label
            items {
              info {
                richText
              }
              title1 {
                text
              }
            }
          }
          ... on PrismicSoftwarePageDataBodyVideoWithText {
            id
            slice_type
            slice_label
            primary {
              content {
                richText
              }
              title1 {
                text
              }
              video {
                url
              }
            }
          }
          ... on PrismicSoftwarePageDataBodyVideoWithTextVertical {
            id
            slice_type
            slice_label
            primary {
              content {
                richText
              }
              heading {
                text
              }
              video {
                url
              }
            }
          }
        }
        logo {
          alt
          gatsbyImageData(placeholder: BLURRED)
        }
        title {
          text
        }
      }
    }
  }
`;

export default SoftwarePage;
